<template>
    <DeadLine 
        :status="record.status" 
        :date="text" />
</template>

<script>
import DeadLine from '@apps/Consolidation/components/DeadLine'

export default {
    props: {
        text: {
            type: String
        },
        record: {
            type: Object,
            required: true
        }
    },
    components: {
        DeadLine
    }
}
</script>